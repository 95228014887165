<template lang="pug">
  .charity(v-show="isShowPopup")
    .charity__wrap
      button.charity__close(@click="closePop")
        img(
          :src="ClosePopImg"
          alt="Close"
        )
      h3 БЛАГОДІЙНИЙ зБІР
      p 📣 Спортивний клуб «Відар» потребує Вашої допомоги для забезпечення комфортних БЕЗКОШТОВНИХ тренувань для дітей та молоді!
      p Наближається зима і наш спортивний зал потребує додаткових витрат на організацію тренувального процесу, оплату комунальних послуг, аренду приміщення та опалення.
      p Тому зверзаємось до всіх, хто має прагнення допомогти і просимо приєднатися до збору необхідних коштів.
      h4 Наші реквізити:
      ul.charity__payment
        li
          h6 Одержувач:
          span Громадська організація «Спортивний клуб «ВІДАР»»
      ul.charity__inputs
        li(
          v-for="(item, key) in inputItems"
          :key="key"
        )
          h6 {{ item.label }}
          .charity__info
            small.charity__message(v-show="item.isAlreadyCopied") Скопійовано
            div {{ item.value }}
            button.charity__copy(@click="copyInfo(key)")
              img(
                :src="GreyCopy"
                alt="Copy"
              )
              img(
                :src="YellowCopy"
                alt="Copy"
              )
      ul.charity__payment
        li
          h6 Банк одержувача:
          span АТ «ОТП БАНК»
        li
          h6 МФО:
          span 300528
      h5 Ми будемо дуже вдячні кожному за підтримку!
      button.link(@click="closePop") Закрити
</template>

<script>
// images
import GreyCopy from '@/assets/images/copied.svg'
import YellowCopy from '@/assets/images/yellow-copy.svg'
import ClosePopImg from '@/assets/images/close-pop.svg'
export default {
  name: 'CharityPop',
  props: {
    isShowPopup: {
      type: Boolean
    }
  },
  data () {
    return {
      GreyCopy,
      YellowCopy,
      ClosePopImg,
      inputItems: [
        { label: 'ЄДРПОУ:', value: '45068818', isAlreadyCopied: false },
        { label: 'Реквізити:', value: 'UA103005280000026001000031821', isAlreadyCopied: false },
        { label: 'Призначення:', value: 'Безповоротна фінансова допомога.', isAlreadyCopied: false }
      ]
    }
  },
  methods: {
    closePop () {
      this.$emit('onClose')
    },
    copyInfo (key) {
      navigator.clipboard.writeText(this.inputItems[key].value)
      this.inputItems[key].isAlreadyCopied = true
      setTimeout(() => {
        this.inputItems[key].isAlreadyCopied = false
      }, 1000)
    }
  }
}
</script>
